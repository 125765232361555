import React, { useEffect } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import CallMissedRoundedIcon from "@mui/icons-material/CallMissedRounded";
import AddIcCallRoundedIcon from "@mui/icons-material/AddIcCallRounded";
import CallReceivedRoundedIcon from "@mui/icons-material/CallReceivedRounded";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ThumbUpOffAltRoundedIcon from "@mui/icons-material/ThumbUpOffAltRounded";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { Link, useNavigate } from "react-router-dom";
import config from "./Utils/config";
import showToast from "./Utils/showToast";
import axios from "axios";
import { PeopleAlt } from "@mui/icons-material";

// Register required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [agent, setAgents] = React.useState([[]]);
  const [queue, setQueue] = React.useState({
    totalCalls: 0,
    priorityCalls: 0,
    estimatedWaitTime: 0,
  });

  const [summary, setSummary] = React.useState({
    callsHandled: 50,
    callsResolved: 48,
    missedCalls: 2,
  });

  const [volumeData, setVolumeData] = React.useState({
    peakHour: "2 PM - 3 PM",
    busiestDay: "Monday",
    callsToday: 150,
  });

  const [satisfaction, setSatisfaction] = React.useState({
    avgRating: 4.2,
    positive: 120,
    negative: 15,
  });

  React.useEffect(() => {
    const fetchQueueData = () => {
      setQueue({
        totalCalls: Math.floor(Math.random() * 50),
        priorityCalls: Math.floor(Math.random() * 10),
        estimatedWaitTime: (Math.random() * 10).toFixed(2),
      });
    };
    fetchQueueData();
    const interval = setInterval(fetchQueueData, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchAgentsData = async () => {
      const apiUrl = config.apiBaseUrl + 'Dashboard/AgentList';
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setAgents(response.data);
      } catch (error) {
        showToast({
          type: 'error',
          message: "Error fetching agents data!",
        });
      }
    };
    fetchAgentsData();
  }, []);

  const handleClick = () => {
    navigate('/admin/Agents');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  const totalActiveAgents = agent.filter(agent => agent.isActive === true).length;
  const totalInactiveAgents = agent.filter(agent => agent.isActive === false).length;
  const totalAgents = agent.length;

  return (
    <div className="dashboard">
      <h1>Admin Dashboard</h1>
      <div className="dashboard-grid">
        <div className="dashboard-grid-item">
          <div className="component">
            <h3>Call Queue Overview</h3>
            <ul>
              <li>
                <AddIcCallRoundedIcon
                  sx={{
                    color: "#07ad23",
                    fontSize: 30,
                    marginRight: "1rem",
                    marginBottom: "3px",
                  }}
                />
                Total Calls: {queue.totalCalls}
              </li>
              <li>
                {" "}
                <StarsIcon
                  sx={{
                    color: "#ed0c1f",
                    fontSize: 30,
                    marginRight: "1rem",
                    marginBottom: "3px",
                  }}
                />
                Priority Calls: {queue.priorityCalls}
              </li>
              <li>
                {" "}
                <HourglassBottomRoundedIcon
                  sx={{
                    color: "#0c91c9",
                    fontSize: 30,
                    marginRight: "1rem",
                    marginBottom: "6px",
                  }}
                />
                Estimated Wait Time: {queue.estimatedWaitTime} minutes
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-grid-item">
          <div className="component">
            <h3>Performance Summary</h3>
            <ul>
              <li>
                <CallReceivedRoundedIcon
                  sx={{ color: "#0c91c9", fontSize: 36, marginRight: "1rem" }}
                />
                Calls Handled: {summary.callsHandled}
              </li>
              <li>
                {" "}
                <CallMadeRoundedIcon
                  sx={{ color: "#07ad23", fontSize: 36, marginRight: "1rem" }}
                />
                Calls Resolved: {summary.callsResolved}
              </li>
              <li>
                {" "}
                <CallMissedRoundedIcon
                  sx={{ color: "#ed0c1f", fontSize: 36, marginRight: "1rem" }}
                />{" "}
                Missed Calls: {summary.missedCalls}
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-grid-item">
          <div className="component">
            <h3>Call Volume</h3>
            <ul>
              <li>
                {" "}
                <WatchLaterRoundedIcon
                  sx={{
                    color: "#f09605",
                    fontSize: 30,
                    marginRight: "1rem",
                    marginBottom: "6px",
                  }}
                />
                Peak Hour: {volumeData.peakHour}
              </li>
              <li>
                <EventBusyRoundedIcon
                  sx={{
                    color: "#ed0c1f",
                    fontSize: 30,
                    marginRight: "1rem",
                    marginBottom: "6px",
                  }}
                />
                Busiest Day: {volumeData.busiestDay}
              </li>
              <li>
                {" "}
                <CallRoundedIcon
                  sx={{
                    color: "#0c91c9",
                    fontSize: 32,
                    marginRight: "1rem",
                    marginBottom: "6px",
                  }}
                />
                Calls Today: {volumeData.callsToday}
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-grid-item">
          <div className="component">
            <h3>Customer Satisfaction</h3>
            <ul>
              <li>
                {" "}
                <StarRoundedIcon
                  sx={{ color: "#f09605", fontSize: 34, marginRight: "1rem" }}
                />
                Average Rating: {satisfaction.avgRating} / 5
              </li>
              <li>
                {" "}
                <ThumbUpOffAltRoundedIcon
                  sx={{
                    color: "#07ad23",
                    fontSize: 29,
                    marginRight: "1rem",
                    marginBottom: "6px",
                  }}
                />
                Positive Feedback: {satisfaction.positive}
              </li>
              <li>
                {" "}
                <ThumbDownRoundedIcon
                  sx={{
                    color: "#111211",
                    fontSize: 29,
                    marginRight: "1rem",
                    marginBottom: "6px",
                  }}
                />
                Negative Feedback: {satisfaction.negative}
              </li>
            </ul>
          </div>
        </div>
        <div onClick={handleClick} onKeyPress={handleKeyPress} role="button" tabIndex="0" style={{ cursor: "pointer" }} className="dashboard-grid-item">
          <div className="component">
            <h3>Agent Details</h3>
            <ul>
              <li>
                <StarsIcon sx={{
                  color: "#07ad23",
                  fontSize: 29,
                  marginRight: "1rem",
                  marginBottom: "6px",
                }} />
                Active Agents: {totalActiveAgents}
              </li>
              <li>
                <EventBusyRoundedIcon sx={{
                  color: "#ed0c1f",
                  fontSize: 29,
                  marginRight: "1rem",
                  marginBottom: "6px",
                }} />
                Inactive Agents: {totalInactiveAgents}
              </li>
              <li>
                <PeopleAlt sx={{
                  color: "#007bff",
                  fontSize: 29,
                  marginRight: "1rem",
                  marginBottom: "6px",
                }} />
                Total Agents: {totalAgents}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dashboard-grid-one">
        <div className="dashboard-grid-item-one">
          <div className="component">
            <h3>Customer Satisfaction</h3>
            <Pie
              data={{
                labels: ["Satisfied", "Neutral", "Unsatisfied"],
                datasets: [
                  {
                    label: "Customer Satisfaction",
                    data: [70, 20, 10],
                    backgroundColor: [
                      "rgba(75, 132, 192, 1)",
                      "rgba(255, 206, 86, 1)",
                      "rgba(255, 99, 132, 1)",
                    ],
                    borderColor: [
                      "rgba(75, 192, 192, 1)",
                      "rgba(255, 206, 86, 1)",
                      "rgba(255, 99, 132, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="dashboard-grid-item-one">
          <div className="component">
            <h3>Call Volume by Hour</h3>
            <Bar
              data={{
                labels: [
                  "12 AM",
                  "3 AM",
                  "6 AM",
                  "9 AM",
                  "12 PM",
                  "3 PM",
                  "6 PM",
                  "9 PM",
                ],
                datasets: [
                  {
                    label: "Calls by Hour",
                    data: [12, 19, 3, 5, 2, 3, 10, 15],
                    backgroundColor: "rgba(75, 132, 192, 1)",
                    borderColor: "rgba(75, 132, 192, 1)",
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="dashboard-grid-item-one">
          <div className="component">
            <h3>Weekly Call Volume</h3>
            <Line
              data={{
                labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
                datasets: [
                  {
                    label: "Weekly Call Volume",
                    data: [50, 75, 100, 125],
                    fill: false,
                    borderColor: "rgba(75, 132, 192, 1)",
                    tension: 0.1,
                  },
                ],
              }}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      {/* Add improved styles for the components */}
      <style>
        {`
          .dashboard {
            padding: 20px;
            font-family: Arial, sans-serif;
             
   background-color: #F4F7FF; 
            min-height: 100vh;
          }

          h1 {
            text-align: center;
            color: #333;
            margin-bottom: 30px;
            font-size: 2rem;
            font-weight: bold;
          }

          .dashboard-grid {
           display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 20px; 
    
          }
          .dashboard-grid-one{
           display: grid;
           margin-top:1rem;
           grid-template-columns: 1fr 2fr 2fr; /* First two columns equal, last one takes up double the space */
           height: 50%; /* Adjust height to 50% of the container */
           gap: 20px; 
    
          }
           .dashboard-grid-item-one {
            background-color: #ffffff;
            
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            max-width: 100%;
            height: 100%;
          }

          .dashboard-grid-item {
            background-color: #ffffff;
            
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            max-width: 100%;
            height: 100%;
          }
        
       

          .component {
            margin: 0;
            padding: 0;
            
          }

          .component h3 {
            margin-bottom: 1.4rem;
            color: #333;
            font-weight:700;
            font-size: 1.2rem;
            {/* border-bottom: 2px solid #75c3c9; */}
            padding-bottom: 5px;
          }

          .component ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .component li {
            margin-bottom: 10px;
            font-size: 1rem;
            color: #555;
          }

          .component li::before {
            {/* content: '•'; */}
            color: #75c3c9;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        `}
      </style>
    </div>
  );
};
//test
export default AdminDashboard;
